<template>
  <div class="container-fluid full-section">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1>400</h1>
          <h4>Данный пользователь заблокирован</h4>
          <p>Данный пользователь заблокирован на этом ресурсе Lukoil.</p>
        </div>
        <div class="col-md-3 col-md-offset-1">
          <div><img class="img_" src="../../assets/images/container.png"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockedUser',
  metaInfo() {
    return {
      title: `Доступ к данному ресурсу ограничен - ЛУКМАРКЕТ`,
    }
  },
}
</script>

<style>
.row {
  min-height: 100vh;
  align-items: center;
}
</style>
